<template>
  <div class="home">
    <div class="container principal bg-principal">
      <div class="container-home">
        <img src="../assets/img/bem-vindo.png" width="96%"/>
        <img src="../assets/img/life-experience.png" width="99%"/>
        <img src="../assets/img/um-lugar-experiencia.png" width="82%"/>
        <img src="../assets/img/heineken.png" width="50%"/>
      </div>
    </div>

    <div class="container sobre">
      <img src="../assets/img/line.png" height="3px" width="100%"/>
      <div class="">
      <p>
        Nós, do Life Experience estamos juntos 
        com a Heineken por um bem maior. Queremos proporcionar as suas melhores experiências em todos os momentos e não 
        podemos esquecer de manter a casa 
        sempre limpa para recebê-los. Por isso, 
        aqui cuidamos e nos preocupamos para 
        onde vai o seu lixo.
      </p>
      <p>
        Maior do que essa casinha que ama festejar e celebrar a vida, existe o nosso 
        planeta que nos acolhe e cuida da gente. 
      </p>
      <p>
      Seu impacto pode ser menor.
      </p>
      <p>
      Fica a vontade e bebe uma Heineken!
      </p>
      <p>
      Mas oh, se liga hein: temos o local certo 
      para o descarte da sua garrafa.
      </p>

      </div>
      <img src="../assets/img/line.png" height="3px" width="100%"/>
    </div>

    <div class="container qr">
    <div class="qr-area">
      <h1 class="title">
        Escaneie o QR Code da porta
      </h1>
      <qrcode-stream
        @decode="onDecode"
        style="width: 68vw; height: 68vw; margin: 4vh 0; border:5px solid #6b4a2e; border-radius:5px; margin: 0 auto;"
      ></qrcode-stream>
      <img src="../assets/img/heineken.png" width="50%" style="margin: 20px auto"/>
    </div>
    </div>
    <!-- <div class="leitorQR" style="height: 7vh"></div> -->
    

    <div v-if="dialog">
      <div class="fundo"></div>
      <div class="dialog">
        <h1 style="font-size: 1.7rem;">Porta liberada!</h1>
        <span @click="dialog = false">Confirmar</span>
      </div>
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

.container-home img {
  margin:auto;
}

.fundo {
  background: #000000c7;
  width: 110vw;
  height: 205vw;
  position: fixed;
  bottom: 0;
  z-index: 3;
}

.dialog {
  background-color: white;
  height: 40vh;
  width: 70vw;
  border-radius: 25px;
  position: fixed;
  top: 25%;
  left: 15%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.dialog span {
  color: #1e88e5;
  font-weight: 600;
}

.dialog::before {
  background-color: black;
  width: 100vw;
  height: 100vw;
  position: fixed;
}

.bg-principal {
  background-image:url("../assets/img/bg-principal2.png");
  background-position:center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
}

.container-home {
  width: 65vw;
  max-width: 600px;
  height: 75vh;
  margin-top: 25vh;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing:border-box;
}

.container {
  height: calc(100vh);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sobre {
  display:flex;
  height: 100vh;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}

p {
  font-family: "Quicksand";
  text-align: justify;
  color: #7a5a3b;
  font-size: 1.1em;
}
.qr {
  background-image:url("../assets/img/bg-qr.png");
  background-position:center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  border-radius: 0px 50vh 50vh 0px;
  display:flex;
  justify-content: center;
  box-sizing:border-box;
  
}
.title {
  text-transform: uppercase;
  color:white;
  padding: 0% 25% 0% 0%;
  text-align: left;
  font-size: 1.8em;
  box-sizing:border-box;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.qr-area {
  box-sizing:border-box;
  display:flex;
  width: 85%;
  height: 70%;
  flex-direction: column;
  justify-content: space-between;
}


.arrow {
  position: fixed;
  z-index: 3;
  right: 0;
  left: 0;
}


@media (min-width: 600px) {

}

@media (min-height: 600px) {

}

</style>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      secaoAtiva: 0,
      arrSecoes: ["principal", "sobre", "leitorQR"],
      dialog: false,
    };
  },
  methods: {
    onDecode(decodedString) {
      if (decodedString == "https://vogue.cyma.digital") { 
       let self = this
       fetch("https://hnk00.cyma.digital/scan-porta/9").then(function(res) { 
          self.dialog = true
          // alert("Porta liberada!")
        })
        
      }
    },
    praBaixo() {
      this.secaoAtiva =
        this.secaoAtiva == this.arrSecoes.length - 1
          ? this.arrSecoes.length - 1
          : (this.secaoAtiva = this.secaoAtiva + 1);
      this.scrollToElement();
    },

    praCima() {
      this.secaoAtiva = this.secaoAtiva == 0 ? 0 : --this.secaoAtiva;
      this.scrollToElement();
      if (this.secaoAtiva == 0) {
        this.$emit("scrollToTop");
      }
    },
    scrollToElement() {
      var el = this.$el.getElementsByClassName(
        this.arrSecoes[this.secaoAtiva]
      )[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    scrollToElm(count) {
      this.secaoAtiva = count;
      var el = this.$el.getElementsByClassName(this.arrSecoes[count])[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
  },
};
</script>
